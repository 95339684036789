<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div
      class="relative model-viewer-container w-full bg-nColorFillGray border border-nColorStrokeGray rounded-xl mt-20 md:mt-3"
      style="height: 50vh"
      v-if="validSource"
    >
      <model-viewer
        ref="modelViewer"
        class="h-full w-full"
        id="modelViewer"
        name="modelViewer"
        :src="gltfSource"
        :ios-src="usdzSource"
        shadow-softness="0"
        shadow-intensity="1"
        camera-controls
        :camera-orbit="'70deg 65deg 64'"
        autoplay
        ar
      >
        <template v-slot:ar-button>
          <button id="ar-button">View in your space</button>
        </template>
      </model-viewer>
      <div class="qr-button hidden md:block">
        <button
          @click="openModal()"
          class="w-24 flex bg-white text-bgcolor pl-5 py-2 rounded-lg uppercase font-semibold z-1 mt-8 absolute top-0 right-4 hover:scale-105 transform"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 512 512"
            id="icons"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline
              points="201.14 64 256 32 310.86 64"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="256"
              y1="32"
              x2="256"
              y2="112"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="310.86 448 256 480 201.14 448"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="256"
              y1="480"
              x2="256"
              y2="400"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="64 207.51 64 144 117.15 112.49"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="64"
              y1="144"
              x2="131.29"
              y2="184"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="448 304.49 448 368 394.85 399.51"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="448"
              y1="368"
              x2="380.71"
              y2="328"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="117.15 400 64 368 64 304.49"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="64"
              y1="368"
              x2="130.64"
              y2="328"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="394.85 112.49 448 144 448 207.51"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <line
              x1="448"
              y1="144"
              x2="380.71"
              y2="184"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <polyline
              points="256 320 256 256 310.86 224"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
            <line
              x1="256"
              y1="256"
              x2="201.14"
              y2="224"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="32"
            />
          </svg>
          <span class="ml-2 text-xl">AR</span>
        </button>
      </div>
      <ImageModal @closeModal="modal.show = $event" :modal="modal" />
    </div>
    <div class="mt-5">
      <ExpFeedback :exp="obkSlugs.birthday" />
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import ImageModal from "@/components/Modals/ImageModal.vue";
import obkSlugs from "@/common/slugs";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  components: {
    ExpFeedback,
    MobileHeader,
    Heading,
    ImageModal,
    Footer,
  },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.demoBirthday,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.gltfSource = data.data.exp_details.gltf;
        this.usdzSource = data.data.exp_details.usdz;
        this.validSource = true;
        this.modal.url = data.data.exp_details.qr;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      obkSlugs,
      gltfSource: null,
      usdzSource: null,
      errorMsg: null,
      validSource: false,
      modal: {
        show: false,
        headline: "QR for Birthday Greetings",
        url: null,
      },
    };
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>
<style scoped>
#ar-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  background: -webkit-gradient(to right, green 0%, brown 50%, pink 100%);
  background-image: linear-gradient(to bottom right, #e040fb, #00bcd4);
  background-size: 100%;
  border: none;
  border-radius: 5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #fff;
  cursor: pointer;
  font: 0.75em sans-serif;
  font-weight: 800;
  height: 2rem;
  letter-spacing: 0.05em;
  outline: 0.1em;
  user-select: none;
  width: 10rem;
  position: absolute;
  top: 16px;
  right: 16px;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}
model-viewer::part(default-ar-button) {
  top: 16px;
  right: 16px;
}
</style>
