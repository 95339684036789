<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          @click="closeModal()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform
                 transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white">
            <div class="sm:flex sm:items-start">
              <div>
                <div
                  class="flex justify-between text-3xl font-semibold text-white bg-bgcolor px-8 py-4"
                >
                  <div>
                    <h3 id="modal-headline">
                      {{ modal.headline }}
                    </h3>
                  </div>
                  <div
                    class="flex items-center cursor-pointer"
                    @click="closeModal()"
                  >
                    <svg class="svg-icon w-4 h-4" viewBox="0 0 20 20">
                      <path
                        fill="#FFFFFF"
                        d="M11.469,10l7.08-7.08c0.406-0.406,0.406-1.064,0-1.469c-0.406-0.406-1.063-0.406-1.469,0L10,8.53l-7.081-7.08
                      c-0.406-0.406-1.064-0.406-1.469,0c-0.406,0.406-0.406,1.063,0,1.469L8.531,10L1.45,17.081c-0.406,0.406-0.406,1.064,0,1.469
                      c0.203,0.203,0.469,0.304,0.735,0.304c0.266,0,0.531-0.101,0.735-0.304L10,11.469l7.08,7.081c0.203,0.203,0.469,0.304,0.735,0.304
                      c0.267,0,0.532-0.101,0.735-0.304c0.406-0.406,0.406-1.064,0-1.469L11.469,10z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="mt-2 px-8 py-4 bg-white">
                  <img :src="modal.url" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageModal",
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    actionType(action) {
      this.$emit("closeModal", false);
      this.$emit(action);
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  z-index: 99999;
}
</style>
